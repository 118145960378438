





























import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import TagsWrapper from '@/modules/scheduled-reports/components/new-report/recipients-section/tags-wrapper.vue';

@Component({
    components: {
        ModalWrapper,
        TagsWrapper,
    },
})
export default class SendExcelReportToEmailPopup extends Vue {
    currentEmailTags: Record<string, string> = {};

    handleEmailTagsChange(payload: { propName: string, value: string }) {
        if (payload.value === null) {
            delete this.currentEmailTags[payload.propName];
        } else {
            this.currentEmailTags[payload.propName] = payload.value;
        }
    }

    handleOkButton() {
        this.$emit('handle-send-to-email-event', this.currentEmailTags);
    }
}
