







import { Component, Vue } from 'vue-property-decorator';
import CarsExcelReportGenerator from '../components/excel-reports/cars-excel-report-generator-popup.vue';

@Component({
    components: { CarsExcelReportGenerator },
})
export default class CarExcelReportGeneratorPage extends Vue {
}
